import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import {Button, Col, Container, Form, Row, Spinner} from 'react-bootstrap';

import Footer from '../Footer';
import TDLogo from '../common/TDLogo';
import styles from './LoginForm.scss';
import { useForm } from '../../utils/useForm';
import FilteringInput from '../input/FilteringInput';
import axios from 'axios';


function SecurityQuestionsForm() {
    const { t } = useTranslation();
    const {fieldErrors, setFieldError} = useForm(validate);
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState(localStorage.getItem('username') || location.state?.username);
    const [isLoading, setIsLoading] = useState(false);
    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [securityQuestionsError, setSecurityQuestionsError] = useState(false);
    const [hasSubmit, setHasSubmit] = useState(false);

    const [error, setError] = useState(false);


    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError(true);
        } else {
            setError(false);
        }
    }

    async function fetchSecurityQuestions() {
        try {
            setIsLoading(true);
            const data = await axios({
                method: 'get',
                url: '/tdfx/security',
                params: {
                    username: username
                }
            });
            if (data === undefined || data.data?.error) {
                setIsLoading(false);
                setSecurityQuestionsError(true);
                // setSecurityQuestions([{
                //     "id": "FAVORITE_CHARITY",
                //     "cat": "1"
                // }, {
                //     "id": "MOTHER_MIDDLENAME",
                //     "cat": "1"
                // }]);
            } else {
                setIsLoading(false);
                setSecurityQuestions(data.data);
            }

        } catch (error) {
            setIsLoading(false);
            setSecurityQuestionsError(true);
        }
    }

    function getSecurityQuestions() {
        setHasSubmit(true);
        if (!error) {
            fetchSecurityQuestions();
        }
    }

    useEffect(() => {
        if (securityQuestions.length > 0) {
            navigate('/login/security', { state: { username: username, securityQuestions: securityQuestions }, replace: true });
        }
    }, [securityQuestions]);


    return (
        <Fragment>
        {isLoading ? 
            <div>
                <div className={`${styles[`forgot-password-spinner`]}`}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">{t('td.fx.public.loading')}</span>
                    </Spinner>
                </div>
                <div>
                    {t('td.fx.forgot.password.retrieving.security.questions')}
                </div>
            </div> : null
        }

        {securityQuestionsError ? 
            <div>
                <div className={`td-error ${styles[`forgot-password-spinner`]}`}>
                    {t('td.fx.forgot.password.cannot.retrieve.security.questions')}
                </div>
                <Button as="a" href="/" className="btn td-btn-secondary-clear btn">{t('td.fx.signing.cancel')}</Button>
            </div> : null
        }

        {!isLoading && !securityQuestionsError ? 
            <div>
                <p className="display-6" style={{ marginTop: '2em'}}>{t('td.fx.forgot.password.title')}</p>
                <p className="display-6">{t('td.fx.forgot.password.signin')}</p>
                <p>{t('td.fx.forgot.password.getstarted')}</p>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>{t('td.fx.login.username')}</Form.Label>
                    <FilteringInput
                        name="username"
                        value={username}
                        required
                        hideError={!hasSubmit}
                        setFieldError={setFieldError}
                        errorPrefix={'td.validation.'}
                        placeholder={'td.fx.login.username'}
                        update={val => setUsername(val)}
                    />
                </Form.Group>
                <div className="d-grid gap-2">
                    <Button onClick={getSecurityQuestions}className={`${styles["identity-verification-btn"]} + btn td-btn-primary-light`}>{t('td.fx.signing.continue')}</Button>
                    <Button as="a" href="/" className="btn td-btn-secondary-clear btn">{t('td.fx.signing.cancel')}</Button>
                </div>
            </div> : null
        }
        </Fragment>
    );
}
function ForgotPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();


    return (
        <Row className={`${styles.fullHeight} + g-0`}>
            <Col xs={12} sm={6} md={5} lg={4} className={`g-0 ${styles.loginWrapper}`}>
                <Container style={{ padding: '4em', boxSizing: 'border-box' }}>
                    <TDLogo/>
                    <SecurityQuestionsForm />
                </Container>
                <Footer login={true}/>
            </Col>
            <Col xs={12} sm={6} md={7} lg={8} className={`g-0 ${styles.loginBackground}`}>
            </Col>
        </Row>
    )
}

export default ForgotPassword;