import React, { useState } from 'react';
import DOMPurify from "dompurify";

export default function Notification({ content, style, icon, dismiss, padding, banner, modal }) {
  const [visible, setVisible] = useState(true);
  const sanitizedHtmlContent = banner ? DOMPurify.sanitize(banner) : null;


  if (visible) {
    return (
      <div className="row td-notification-compact" style={modal? {padding: '0px', margin: '10px'} : { padding: (padding == 0)? '0px 10px 0px 5px': '0 3em', marginBottom: padding ? padding : '2em', marginRight: '0px' }}>
          <div className={`col-sm-12 bg-${style} td-notification-wrapper`}> 
            { icon &&
              <span aria-hidden="true" className={`td-icon icon-regular td-icon-${icon}`}></span>
            }
            { banner ? 
              <div style={{ padding: 0 }} dangerouslySetInnerHTML={{__html: sanitizedHtmlContent}}></div>
              : <p>{content}</p>
            }
            { dismiss && 
              <button onClick={() => setVisible(false)} className="td-icon-button-delete td-icon-button-delete-xs" aria-label="Close"><span className="visually-hidden">Accessibility Description</span></button>
            }
          </div>
      </div>
    )
  } 
}