import React from 'react';
import { NavLink  } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';

export default function ProfileMenu({ isOpen, toggle, name, logout }) {
    const { t } = useTranslation();

    if (!isOpen) 
        return null;

    return (
        <div class="td-nav-mobile">
            <div class="td-nav-mobile-menu td-nav-mobile-menu-secondary td-nav-mobile-menu-right">
                <div class="td-nav-mobile-menu-top">
                    <div class="td-nav-mobile-menu-header">
                        <button type="button" class="td-mobile-menu-close" onClick={() => toggle(false)}>
                            <span className="visually-hidden">Close menu</span><span class="td-icon-button-delete td-icon-button-delete-sm"  aria-hidden="true"></span>
                        </button>
                        <div class="td-nav-mobile-menu-title"><span class="td-icon td-icon-avatar" aria-hidden="true"></span>{name}</div>
                    </div>
                </div>
                <nav>
                    <ul class="td-nav-mobile-menu-list">
                        <li class="td-nav-mobile-menu-item td-item-nav-link">
                            <Nav.Link as={NavLink} to={'/questions'} key={'questions'} onClick={() => toggle(false) }>{t('td.fx.usermenu.update.security.questions')}</Nav.Link>
                        </li>
                        <li class="td-nav-mobile-menu-item td-item-nav-link">
                            <Nav.Link onClick={() => { toggle(false); logout(); }}>
                                <span className="td-icon td-icon-noIcon"></span>{t('td.fx.logout')}
                            </Nav.Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="td-nav-mobile-overlay" style={{ opacity: '1'}}></div>
        </div>
    );
}