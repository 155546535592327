import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DOMPurify from "dompurify";

import { User } from '../../services/userService';

import en from './en_TDFXTermsConditions.html';
import en_US from './us_TDFXTermsConditions.html';
import fr from './fr_TDFXTermsConditions.html';

export default function LegalAgreement({ model }) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(model.needsLegalAgreement);
  const isUSCustomer = User.isUSCustomer(model.companies);

  function getTemplate() {
    if (isUSCustomer){
      return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(en_US)}}></div>;
    } else {
      const language = i18n.language.substr(0,2);
      return <div dangerouslySetInnerHTML={{__html: language == 'en' ? DOMPurify.sanitize(en) : DOMPurify.sanitize(fr) }}></div>;
    }
  }

  const ackLegalAgreement = () => {
    User.ackLegalAgreement(isUSCustomer);
    setOpen(false);
  };

  const logout = () => {
    User.logout(() => {
      console.log('we have logged out');
    });
    setOpen(false);
  };

  return (
    <Modal show={open} centered className="td-modal" style={{ '--td-modal-width': 'auto' }}>
      <Modal.Header></Modal.Header>
      <Modal.Body className="td-modal-body-shown" style={{ maxHeight: '500px', overflow: 'scroll' }}>
        {getTemplate()}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={logout} className="btn td-btn-secondary-clear"> {t('td.fx.disagree')}</Button>
        <Button onClick={ackLegalAgreement} className="btn td-btn-primary-light">{t('td.fx.agree')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
