import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import WirePayment from './forms/wirePayment';
import History from './forms/history';
import styles from './TradeSettlement.scss';

export default function TradeSettlement() {
    const { t } = useTranslation();

    return (
        <div className="main-container">
            <Tabs defaultActiveKey="wire">
                <Tab eventKey="wire" title={t('td.fx.tradeSettlement.paymentInstruction')} tabClassName={`${styles["tab-nav"]}`}>
                    <WirePayment/>
                </Tab>
                <Tab eventKey="history" title={t('td.fx.tradeSettlement.bankAccounts')} tabClassName={`${styles["tab-nav"]}`}>
                    <History/>
                </Tab>
            </Tabs>
        </div>
    )
}