import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useForm } from '../../utils/useForm';

import FilteringInput from '../input/FilteringInput';
import './SecurityQuestions.scss';

function SecurityQuestions() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const {fieldErrors, setFieldError} = useForm(validate);
    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState(new Array(3));
    const [hasSubmit, setHasSubmit] = useState(false);
    const [submitError, setSubmitError] = useState();
    const isLogin = window.location.href.includes('login');

    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError(true);
        } else {
            setError(false);
        }
    }

    function doSubmit(e) {
        setHasSubmit(true);
        // don't do default stuff
        e.preventDefault();
        e.stopPropagation();

        if (error) {
            // there's an error
            return false;
        }

        let request = {};
        for (let index in questions) {
            var prop = questions[index];
            request[prop] = answers[index];
        }

        fetch('/tdfx/api/security', { method: 'POST', 
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(request)
        })
        .then(res => res.json())
        .then((data) => {
            if (data.error) {
                setSubmitError(data.error);
            } else {
                navigate(isLogin ? '/tdfx' : '/', { replace: true });
            }
        });
    }

    function doCancel(e) {
        e?.preventDefault();
        if (isLogin) {
            navigate('/tdfx', { replace: true });
        } else {
            navigate('/tdfx');
        }
    }

    useEffect(() => {
        fetch('/tdfx/api/security', { method: 'GET' })
            .then(res => res.json())
            .then((data) => {
                let qs = [];
                const category = groupBy(data, 'cat');
                Object.keys(category).forEach((key) => {
                    qs.push(_.map(category[key], function (it) {
                        return it.code;
                    }));
                });

                setSecurityQuestions(qs);
                setQuestions([...questions, qs[0][0], qs[1][0], qs[2][0]]);
            });
    }, []);

    return (
        <div className="main-container security-questions">
            <h1>{t('td.fx.security.setup.password.recovery.title')}</h1>
            <p>{t('td.fx.security.setup.password.recovery.subtitle')}</p>

            <Form noValidate onSubmit={doSubmit}>
                { securityQuestions.map((q, index) => 
                    <Row className="gy-4" key={index}>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label></Form.Label>
                                <Form.Select 
                                    value={questions[index]}
                                    onChange={(e) => {
                                        questions[index] = e.target.value;
                                        setQuestions([...questions]);
                                    }}
                                >
                                    { securityQuestions[index].map((question) => 
                                        <option key={question} value={question}>{t(`td.fx.security.question.${question}`)}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label></Form.Label>
                                <FilteringInput required
                                    name={`answers${index}`}
                                    value={answers[index]}
                                    hideError={!hasSubmit}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.fx.security.error.'}
                                    placeholder={'td.fx.security.answer'}
                                    maxLength={25}
                                    update={(val) => {
                                        answers[index] = val;
                                        setAnswers([...answers]);
                                    }}
                                    regex={/^[a-zA-Z0-9]{3,25}$/}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                
                <Form.Label className={'td-error'} hidden={!submitError}>{ t(`${submitError}`) }</Form.Label>

                <Row className="gy-4">
                    <Col xs={12} sm={12} md={12} lg={3}>
                        <Row style={{ marginTop: '3em' }}>
                            <Col xs={6} sm={6} md={6}>
                                <div className="d-grid gap-2"> 
                                    <Button as="a" href="" className="td-btn-secondary-clear btn btn-primary" onClick={doCancel}>{t('td.fx.security.cancel')}</Button>
                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={6}>
                                <div className="d-grid gap-2">
                                <Button className="btn td-btn-primary-light" type="submit">{t('td.fx.security.continue')}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>

    );
}

export default SecurityQuestions;