import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// import styles from './RFQModal.scss';


function LogoutError({ sessionTimeout, closeTimeoutError, logoutError, noRolesError }) {
    const { t } = useTranslation();

    // closeOnEscape={false} - maybe?
    // FIXME - how are we deciding title
    // logoutError + '.title', + '.message' ?
    return (
        <Modal show={!!sessionTimeout} centered className="td-modal" onClose={closeTimeoutError} >
            { /* <Modal.Header className={styles.noflex}> */ }
            <Modal.Header >
                <Modal.Title>
                    {t('tdfx.user.error.timeout.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="td-modal-body-shown">
            <p> {t(logoutError || 'tdfx.user.error.timeout.message')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn td-btn-primary-light" onClick={closeTimeoutError}>
                        {t('tdfx.user.error.disconnected.login')}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    /*
        <Fragment>
            <Modal open={!!sessionTimeout} onClose={closeTimeoutError} dimmer={'inverted'} basic size="small" closeOnDimmerClick={false}>
                <Header content={t('tdfx.user.error.timeout.title')} />
                <Modal.Content>
                    <p> {t(logoutError || 'tdfx.user.error.timeout.message')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeTimeoutError}>
                        {t('tdfx.user.error.disconnected.login')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={!!noRolesError }  onClose={closeTimeoutError} dimmer={'inverted'} basic size="small" closeOnDimmerClick={false}>
                <Header content={t('tdfx.user.error.not.authorized.title')} />
                <Modal.Content>
                    <p>{t('tdfx.user.error.not.authorized.message')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeTimeoutError} >
                        {t('tdfx.user.error.disconnected.login')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
        */
}

export { LogoutError };
