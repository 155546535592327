import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './Modal.scss';

function noOp(x) {
    return x;
}

export default function ConfirmationModal({ bodyText, display, onClose, onConfirmation, setDisplay, showCancel = true }) {
    onClose = onClose || noOp;
    onConfirmation = onConfirmation || noOp;

    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
        setDisplay(false);
    };

    const handleShow = () => {
        setDisplay(true);
    }

    const handleConfirmation = () => {
        onConfirmation();
        setDisplay(false);
    };

    const handleCancel = () => {
        setDisplay(false);
    };

return (
        <Modal show={display} onHide={handleClose}>
            <div className={`${styles["modal-container"]}`}>
                <Modal.Body className={`${styles["confirmation-body"]}`}>
                    <p>{t(bodyText)}</p>
                </Modal.Body>
                <Modal.Footer className={`${styles["confirmation-footer"]}`}>
                    <Button className={`btn td-btn-primary-light ${styles["confirmation-button"]}`} onClick={handleConfirmation}>
                        {t('td.fx.ticket.modal.ok')}
                    </Button>
                    { showCancel && 
                    <Button className={`btn btn td-btn-secondary-clear`} onClick={handleCancel}>
                        {t('td.fx.security.cancel')}
                    </Button>
                    }
                </Modal.Footer>
            </div>
    </Modal>
    );
}