// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nkz5RO20cdm9ZnsIgWOr{padding-top:50px !important}.HVyf2drbraeWYm0TZNY5{padding-bottom:15px !important;padding-right:15px !important}.AGSkVzifXvUzU4Joy4_u{margin-right:10px !important}.RW18lCBprQVWugNz_hwt{padding-top:50px;padding-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/components/TradeSettlement/forms/Modal.scss"],"names":[],"mappings":"AAAA,sBACI,2BAAA,CAEJ,sBACI,8BAAA,CACA,6BAAA,CAEJ,sBACI,4BAAA,CAGJ,sBACI,gBAAA,CACA,mBAAA","sourcesContent":[".confirmation-body {\n    padding-top: 50px !important;\n}\n.confirmation-footer {\n    padding-bottom: 15px !important;\n    padding-right: 15px !important;\n}\n.confirmation-button{\n    margin-right: 10px !important;\n}\n\n.modal-container {\n    padding-top: 50px;\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation-body": `Nkz5RO20cdm9ZnsIgWOr`,
	"confirmation-footer": `HVyf2drbraeWYm0TZNY5`,
	"confirmation-button": `AGSkVzifXvUzU4Joy4_u`,
	"modal-container": `RW18lCBprQVWugNz_hwt`
};
export default ___CSS_LOADER_EXPORT___;
