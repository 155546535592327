import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useModel } from './../RequestForQuote/useModel';
import { useForm } from '../../utils/useForm';
import FilteringInput from '../input/FilteringInput';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import styles from './LoginForm.scss';
import TDLogo from '../common/TDLogo';
import axios from 'axios';

function IdentityVerification() {
    const location = useLocation();

    //state variables
    const [error, setError] = useState(false);
    const [forgotAnswers, setForgotAnswers] = useState(false);
    const [hasSubmit, setHasSubmit] = useState(false);
    const [model, setModel] = useState({});
    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [username, setUsername] = useState(localStorage.getItem('username') || location.state?.username);

    const { handleChange } = useModel(model, updateModel);
    const { t } = useTranslation();
    const { fieldErrors, setFieldError } = useForm(validate);
    const navigate = useNavigate();

    function updateModel(m) {
        setModel(m);
        validate();
    }

    function validate(fields, m) {
        let data = m || model;

        const fieldObj = fields || fieldErrors;
        if (Object.values(fieldObj).reduce((accumulator, curr) => accumulator || !!curr, false)) { //check for field errors
            setError(true);
        } else if (data.newPassword !== data.confirmPassword) { //check if new & old passwords match
            setError('td.fx.security.error.passwords.do.not.match');
        } else {
            setError(false);
        }
    }

    function handleSubmit(e) {
        setHasSubmit(true);

        // prevent default submission behaviours
        e.preventDefault();
        e.stopPropagation();

        validate();

        if (!error) {
            const data = {
                username: username,
                password: model.newPassword,
                answers: getAnswers()
            };
            resetPassword(data);
        }
    }

    function getAnswers() {
        let answers = securityQuestions.map((question, index) => ({...question, "answer": model[`answer${index+1}`]}));
        return answers;
    }

    const resetPassword = async (data) => {
            try {
                const res = await axios({
                    method: 'POST',
                    url: '/tdfx/reset',
                    data: data
                });

                if (res.data.success) {
                    navigate('/login', { state: { username: username }, replace: true });
                } else {
                    setError(res.data.error);
                }

            } catch (error) {
                setError(error);
            }
    };

    const updateForgotAnswer = () => {
        setForgotAnswers(true);
    }

    //initial component render
    useEffect(() => {
        setSecurityQuestions(location.state?.securityQuestions);
    }, []);


    return (
        <>
            {forgotAnswers === false ? <Row className={`${styles.fullHeight} + g-0`}>
                <Col className={`${styles.loginBackground}`}>
                    <Container className={`${styles["identity-verification-content"]} ${styles["identity-verification-container"]}`}>
                        <TDLogo/>
                        <Form noValidate onSubmit={handleSubmit}>
                            {/* Title heading */}
                            <Row className={`${styles["identity-verification-center"]}`}>
                                <Col xs={12} sm={12} lg={12}>
                                    <h1>{t('td.fx.security.title')}</h1>
                                </Col>
                            </Row>
                            <Row className={`${styles["identity-verification-center"]}`}>
                                <Col xs={12} sm={12} lg={12}>
                                    {t('td.fx.security.answerhdr')}
                                </Col>
                            </Row>
                            <Row className={`${styles["identity-verification-center"]}`}>
                                <Col xs={12} sm={12} lg={12}>
                                    {t('td.fx.security.identity')}
                                </Col>
                            </Row>
                            <Row className={`${styles["identity-verification-questions"]}`}>
                                {/* Form controls for the user to answer security questions */}
                                {!!securityQuestions.length && securityQuestions.map(({id}, index) => {
                                    return <Row key={index} className={`${styles["identity-verification-form-element"]}`}>
                                                <Form.Group as={Col} xs={12} sm={12} lg={7}>
                                                    <Form.Label htmlFor={`question${index+1}`}>
                                                        {t(`td.fx.security.question.${id}`)}
                                                    </Form.Label>
                                                    <FilteringInput required
                                                                    errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name={`answer${index+1}`}
                                                                    placeholder={'td.fx.security.answer'}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleChange(e, `answer${index+1}`)}
                                                                    value={model[`answer${index+1}`]}/>
                                                </Form.Group>
                                           </Row>
                                    })
                                }
                                {/* New password section*/}
                                <Row className={`${styles["identity-verification-form-element"]}`}>
                                    <Form.Group as={Col} xs={12} sm={12} lg={7}>
                                        <Form.Label htmlFor="newPassword">
                                            {t('td.fx.security.new.password')}
                                        </Form.Label>
                                        <FilteringInput required
                                                        errorPrefix={'td.validation.'}
                                                        hideError={!hasSubmit}
                                                        name="newPassword"
                                                        password
                                                        placeholder={'td.fx.placeholder.new.password'}
                                                        setFieldError={setFieldError}
                                                        update= {(e) => handleChange(e, "newPassword")}
                                                        validate={(val) => (val && (val.length < 6 || val.length > 25)) ? "password.pattern" : false}
                                                        value={model.newPassword}/>
                                    </Form.Group>
                                </Row>
                                <Row className={`${styles["identity-verification-form-element"]}`}>
                                    <Form.Group as={Col} xs={12} sm={12} lg={7}>
                                        <Form.Label htmlFor="confirmPassword">
                                            {t('td.fx.security.confirm.password')}
                                        </Form.Label>
                                        <FilteringInput required
                                                        errorPrefix={'td.validation.'}
                                                        hideError={!hasSubmit}
                                                        name="confirmPassword"
                                                        password
                                                        placeholder={'td.fx.placeholder.confirm.password'}
                                                        setFieldError={setFieldError}
                                                        update={(e) => handleChange(e, "confirmPassword")}
                                                        validate={(val) => (val && (val.length < 6 || val.length > 25)) ? "password.pattern" : false}
                                                        value={model.confirmPassword}/>
                                    </Form.Group>
                                </Row>
                                <Row className={`${styles["identity-verification-center"]}`}>
                                    {/* Error handling section*/}
                                    <Form.Label className={'td-error'} hidden={!error}>
                                        {error && typeof error != 'object' && typeof error != 'boolean' && t(error)}
                                    </Form.Label>
                                </Row>
                                {/* Button section */}
                                <Row className={`${styles["identity-verification-last-row"]}`}>
                                    <Col xs={12} sm={12} lg={{ span: 3 }}>
                                        <div className="d-grid gap-2">
                                            <Button as="a" href="/" className="btn td-btn-secondary-clear btn">
                                                {t('td.fx.signing.cancel')}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} lg={{ span: 3, offset: 3}} className={`${styles["identity-verification-continue"]}`}>
                                        <div className="d-grid gap-2">
                                        <Button className={`btn td-btn-primary-light`} type="submit">
                                            {t('td.fx.signing.continue')}
                                        </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`${styles["identity-verification-center"]}`} style={{ marginTop: '2em'}}>
                                    <Col xs={12} sm={12} lg={12}>
                                        <Link className="display-6" onClick={updateForgotAnswer}>
                                            {t('td.fx.security.forgotanswer')}
                                        </Link>
                                    </Col>
                                </Row>
                            </Row>
                        </Form>
                    </Container>
                </Col>
                </Row> : null
            }
            {/* Forgot Answers section appears if user clicks 'Forgot your answers?' link*/}
            {forgotAnswers ? <Row className={`${styles.fullHeight} + g-0`}>
                    <Col className={`${styles.loginBackground}`}>
                        <Container className={`${styles["identity-verification-content"]} ${styles["identity-verification-container"]}`}>
                            <TDLogo/>
                            {/* Title heading */}
                            <Row className={`${styles["identity-verification-center"]}`} style={{ marginTop: '3em' }}>
                                <h1>{t('td.fx.security.title')}</h1>
                            </Row>
                            <Row className={`${styles["identity-verification-center"]}`}>
                                <Col>
                                    {t('td.fx.security.forgotanswer')}
                                </Col>
                            </Row>
                            <Row className={`${styles["identity-verification-center"]}`}>
                                <Col>
                                    {t('td.fx.forgot.forgot.security.answers')}
                                </Col>
                            </Row>
                            {/* Button section */}
                            <Row className={`${styles["identity-verification-center"]}`} style={{ marginTop: '3em' }}>
                                <Col>
                                    <Link to="/login">
                                        <Button as="a" href="/login" className={`btn td-btn-primary-light`}>{t('td.fx.signing.continue')}</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row> : null
            }
        </>
    );
}

export default IdentityVerification;