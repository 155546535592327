import React, { useState, Fragment, useEffect, useCallback } from 'react';
import i18next from 'i18next';

import { Format } from '../../services/formatService';

import ValidatingInput from './ValidatingInput';


function parseAmount(val) {
    val = val || '';
    // strip commas and handle multipliers
    // except we don't know if those are , or . because French
    
    // let newValue = val.replace(/[,]/g,"");
    // this is duplication from FormaterService - but that function won't parse our multipliers
    const thousands = i18next.t('td.fx.currency.separator.thousands');
    const decimal = i18next.t('td.fx.currency.separator.decimal');
    let newValue = val.replaceAll(thousands, '');
    newValue = newValue.replaceAll(decimal, '.');

    // checks for existences of the multipliers in the input
    const multiplierMatch =  newValue.match(/^[0-9]+([htkmbHTKMB]){1}/);

    
    if (multiplierMatch) {
        const multiplier = multiplierMatch[1];
        // converts characters h to 00, k to 000, m to 000000 and so on
        const mPos = newValue.indexOf(multiplier);            
        switch (multiplier.toString().toLowerCase()) {
            case 'h': newValue = newValue.slice(0, mPos) + "00"; break;
            case 'k': case 't':  newValue = newValue.slice(0, mPos) + "000"; break;
            case 'm': newValue = newValue.slice(0, mPos) + "000000"; break;
            case 'b': newValue = newValue.slice(0, mPos) + "000000000"; break;
        }
        // if input is 1k23 (for example), it will be converted to 1000 + 23 = 1023 and so on
        const sumDigits = (mPos + 1) ===  newValue.length ? null : newValue.slice(mPos + 1);
        if(sumDigits) {
            newValue = sumDigits.startsWith(".") ? newValue + sumDigits : newValue.slice(0, newValue.length - sumDigits.length) + sumDigits;
        }
    }

    // cuts number to 2 decimals
    if (newValue.match(/[.]{1}[0-9]*$/)) {
        newValue = newValue.slice(0, newValue.indexOf(".") + 3);
    }
    
    // strip leading zeroes, but leave leading in 0.
    let hasDecimal = newValue.match(/[.]{1}[0-9]{1}[0-9]{1}$/);

    while (newValue.startsWith('0') && (newValue.length > 1) && (!hasDecimal || hasDecimal?.index > 1)) {
        newValue = newValue.slice(1, newValue.length);
        hasDecimal = newValue.match(/[.]{1}[0-9]{1}[0-9]{1}$/);
    }

    // maybe strip off 3rd+ decimal here
    return newValue;
}


function formatAmount(val) {

    return Format.formatAmount(val);
    /*
    let newValue = val || '';
    // FIXME - written to allow multipliers in the middle of the text, but we don't want that
    
    // if number ends with ., removes .
    if (newValue.endsWith('.')) {
        newValue = newValue.slice(0, newValue.length - 1);
    }
    // cuts number to 2 decimals
    if (newValue.match(/[.]{1}[0-9]*$/)) {
        newValue = newValue.slice(0, newValue.indexOf(".") + 3);
    }
    // if number has 1 decimal, add 0 to the end
    if (newValue.match(/[.]{1}[0-9]$/)) {
        newValue += '0';
    }

    // insert commas
    if (newValue.length > 3) {
        const hasDecimal = newValue.match(/[.]{1}[0-9]{1}[0-9]{1}$/);

        const startPos = (hasDecimal) ? newValue.length - 3 : newValue.length;
        for(let i = startPos ; i > 3 ; i = i - 3 ) {
            newValue = newValue.slice(0, i - 3) + ',' + newValue.slice(i - 3, newValue.length);
        }

    }
    return newValue;
    */
}

export default function AmountInput({name, value, update, setFieldError, errorPrefix, className, minValue, maxValue = 10000000000, blur, placeholder}) {
    const validateAmount = useCallback((val) => {
        val = val || '';
        // if the amount is '', then it's valid
        if (val === '') {
            return false;
        }
        let validNumber = val === '' || val.match(/^[0-9]+[,]*[htkmbHTKMB]?$/) || val.match(/^[0-9,]+[.]?[0-9]*$/);
        // if it's valid we need to format/parse
        let testVal = parseAmount(formatAmount(val));
        // now test min/max and decimals
        if (minValue && testVal < minValue) {
            return {key:'amount.too.small', min: minValue};
        } else if (maxValue && testVal > maxValue) {
            return {key: 'amount.too.big', max: maxValue};
        }
        /*
        "td.validation.invalid_number.too.many.decimals" : "Invalid number, max [decimals] decimal digits.",
        "td.validation.invalid_number.too.big" : "Invalid number, maximum is {{max}}.",
        "td.validation.invalid_number.too.small" : "Invalid number, minimum is {{min}}.",
        "td.fx.ticket.drawdown.error.invalid.amount": "Please enter an amount equal to or less than remaining value.",

        */
        return validNumber ? false : 'amount';
    }, [minValue, maxValue]);

    return (
        <ValidatingInput 
            name={name} 
            value={value} 
            update={update} 
            setFieldError={setFieldError} 
            errorPrefix={errorPrefix} 
            inputValidation={validateAmount} 
            format={formatAmount} 
            parse={parseAmount} 
            className={className}
            blur={blur}
            placeholder={placeholder}
        />
    );
}

